<block-ui>
  <div class="wrapper" id="wrapper" appScreenBehavior>
    <ng-container *ngIf="showDefaultHeader; else customHeader">
      <app-header [notifyList]="alertNotifyList"></app-header>
    </ng-container>
    <ng-template #customHeader>
      <app-custom-header></app-custom-header>
    </ng-template>
    <router-outlet (activate)="onRouterChange($event)"></router-outlet>
  </div>
  <app-footer></app-footer>
</block-ui>