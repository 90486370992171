var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { LoginService } from '../shared/services/login.service';
import { ServerStatusService } from '../shared/services/server-status.service';
import { CreateAccountService } from '../shared/services/create-account.service';
import { LoginValues } from '../shared/mock/login-values';
import { ModalComponent } from '../modal/modal.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { BlockUI } from 'ng-block-ui';
import { HttpClient } from '@angular/common/http';
import { SearchService } from 'app/shared/services/search.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(loginService, serverStatusService, createAccountService, router, formBuilder, http, deviceService, searchService) {
        this.loginService = loginService;
        this.serverStatusService = serverStatusService;
        this.createAccountService = createAccountService;
        this.router = router;
        this.formBuilder = formBuilder;
        this.http = http;
        this.deviceService = deviceService;
        this.searchService = searchService;
        this.LoginTextValues = LoginValues;
        this.credentials = {
            username: '',
            grant_type: 'password',
            password: ''
        };
        this.radiusActive = true;
        this.emptyUser = false;
        this.emptyPass = false;
        this.loginError = 'You must add a password.';
        this.emailRequired = "*An Email is required.";
        this.emailError = '*Invalid email.';
        this.userEmail = '';
        this.sendEmail = new FormControl('', [Validators.required]);
        this.multipleAccountForm = new FormControl('', [Validators.required]);
        this.buttonStatus = true;
        this.total = 0;
        this.textMessage = '';
        this.materialIconName = '';
        this.accounts = [];
        this.accountsClone = [];
        this.userAccessList = [];
        this.customerID = 0;
        this.scrolledToEnd = false;
        this.customer = {};
        this.searchAccount = '';
        this.loginForm = formBuilder.group({
            'username': [null, Validators.required],
            'password': [null, Validators.required]
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        if (this.epicFunction()) {
            this.getIPAddress();
            this.onHandleLoginSubscriptions();
            this.buttonStatus = true;
        }
        else {
            this.buttonStatus = false;
            this.blockUi.stop();
            this.emptyPass = true;
            this.loginError = this.serverStatusService.status(402, 'Your browser is not supported by the site.');
            this.loginForm.reset();
        }
    };
    LoginComponent.prototype.onHandleLoginSubscriptions = function () {
        var _this = this;
        this.loginService.authenticate
            .subscribe(function (value) { return value ? _this.router.navigateByUrl('/dashboard', { replaceUrl: true }) : false; });
        this.createAccountService.getWebsiteAnnouncements()
            .subscribe(function (announcement) { return _this.websiteAnnouncement = announcement; });
        this.loginService.accessCustomerList
            .subscribe(function (value) { return _this.userAccessList = value; });
    };
    LoginComponent.prototype.ngOnDestroy = function () { };
    LoginComponent.prototype.signIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.credentials['username']) {
                            this.emptyUser = true;
                        }
                        if (!this.credentials['password']) {
                            this.emptyPass = true;
                        }
                        if (!(!this.emptyUser && !this.emptyPass)) return [3 /*break*/, 4];
                        this.blockUi.start('Loading...');
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, this.loginService.signIn(this.credentials).toPromise()];
                    case 2:
                        _a.token = _b.sent();
                        this.customer.UserId = this.token.userId;
                        this.customer.CustomerNumber = this.token.customernumber;
                        if (this.userAccessList.length > 0) {
                            this.blockUi.stop();
                            this.accounts = this.userAccessList;
                            this.accountsClone = this.accounts;
                            this.modalMultipleAccounts();
                        }
                        else {
                            this.router.navigate(['/dashboard']);
                        }
                        this.RecordAuditSiteLogin('');
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _b.sent();
                        this.blockUi.stop();
                        this.emptyPass = true;
                        this.loginError = this.serverStatusService.status(err_1.status, err_1.error);
                        this.loginForm.reset();
                        return [3 /*break*/, 4];
                    case 4:
                        if (!(this.userAccessList.length === 0)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.loginService.customerLogin(this.customer)];
                    case 5:
                        _b.sent();
                        _b.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.cleanLogin = function (event) {
        var value = event.target.value;
        if (value.length > 0 && event.keyCode !== 13) {
            this.emptyUser = false;
            this.emptyPass = false;
            this.loginError = 'You must add a password.';
        }
    };
    LoginComponent.prototype.continue = function () {
        this.router.navigate(['account']);
    };
    LoginComponent.prototype.modalPass = function () {
        this.forgotPass.showChildModal();
    };
    LoginComponent.prototype.sendMail = function (email) {
        return __awaiter(this, void 0, void 0, function () {
            var response, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.blockUi.start('Loading...');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.createAccountService.forgotPassword(email).toPromise()];
                    case 2:
                        response = _a.sent();
                        this.email_sent = true;
                        this.textMessage = response.isFound ? 'Email sent!' : 'Email not found!';
                        this.materialIconName = response.isFound ? 'check' : 'clear';
                        this.blockUi.stop();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.blockUi.stop();
                        throw new Error(err_2);
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.resetView = function (event) {
        this.email_sent = false;
        this.userEmail = '';
        this.sendEmail.markAsPristine();
    };
    LoginComponent.prototype.setCustomerID = function (customerID) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.customerID = customerID;
                if (this.customerID && this.customerID > 0) {
                    this.loginService.setAccount(this.customerID).then(function () {
                        _this.loginService.logInQwikkitOrdering(_this.credentials);
                    });
                    this.multipleAccounts.hideChildModal();
                    this.customer.CustomerId = this.customerID;
                    this.loginService.customerLogin(this.customer);
                }
                return [2 /*return*/];
            });
        });
    };
    LoginComponent.prototype.epicFunction = function () {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        var browser = this.deviceInfo.browser;
        if (browser === 'Chrome' || browser === 'MS-Edge' || browser === 'Firefox' || browser === 'MS-Edge-Chromium' || browser === "Safari") {
            return true;
        }
        else {
            return false;
        }
    };
    LoginComponent.prototype.CalculateLeadgeTotal = function () {
        if (this.customerLedge !== undefined) {
            for (var i = 0; i < this.customerLedge.length; i++) {
                this.total = this.total + this.customerLedge[i].Remaining_Amt_LCY;
            }
        }
    };
    LoginComponent.prototype.modalMultipleAccounts = function () {
        this.multipleAccounts.showChildModal();
    };
    LoginComponent.prototype.openNewTabNewAccount = function () {
        window.open('https://qwikkit.com/contact-us/', '_blank');
    };
    LoginComponent.prototype.openTabHomeOwner = function () {
        window.open('https://bit.ly/3stMusC', '_blank');
    };
    LoginComponent.prototype.RecordAuditSiteLogin = function (ipAddress) {
        this.createAccountService.recordSIteAudit(ipAddress).toPromise();
    };
    LoginComponent.prototype.getIPAddress = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.createAccountService.recordSIteAuditVisitor().toPromise();
                return [2 /*return*/];
            });
        });
    };
    LoginComponent.prototype.onSearchAccounts = function (searchValue) {
        this.accounts = this.accountsClone;
        this.accounts = this.searchService.search(this.accounts, searchValue, ['customerNumber']);
    };
    LoginComponent.prototype.onScroll = function (event) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
            this.scrolledToEnd = true;
        }
    };
    __decorate([
        BlockUI(),
        __metadata("design:type", Object)
    ], LoginComponent.prototype, "blockUi", void 0);
    return LoginComponent;
}());
export { LoginComponent };
